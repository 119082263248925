/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import {getHelpUrl, isLDAPEnabled} from 'containers/App/AppState';
import {isSuperclusterMember, isUserOwner} from 'containers/User/UserState';
import ldapConfigReducers from './LDAP/View/AuthenticationSettingsLDAPViewState';

export default {
  authenticationSettings: combineReducers({
    settings(state = {}, action) {
      switch (action.type) {
        case 'AUTHENTICATION_GET_SETTINGS':
          return action.data;
        default:
          return state;
      }
    },
    ldap: combineReducers({
      ...ldapConfigReducers,
      ldapConfigs(state = {}, action) {
        switch (action.type) {
          case 'GET_LDAP_CONFIGS':
            return action.data;
          default:
            return state;
        }
      },
      verifyConnection(state = {}, action) {
        switch (action.type) {
          case 'UPDATE_VERIFY_CONNECTION':
            return action.data;
          default:
            return state;
        }
      },
    }),
    saml: combineReducers({
      config(state = {}, action) {
        switch (action.type) {
          case 'SAML_CONFIGS_GET_CONFIG':
            return action.data;
          default:
            return state;
        }
      },
      samlConfigs(state = {}, action) {
        switch (action.type) {
          case 'GET_SAML_CONFIGS':
            return action.data;
          default:
            return state;
        }
      },
    }),
  }),
};

export const getSettings = state => state.authenticationSettings.settings;
export const getVerifyConnection = state => state.authenticationSettings.ldap.verifyConnection;

export const getHelpLinks = createSelector(getHelpUrl, url => {
  return {
    onelogin: {
      link: `${url}one login single sign-on`,
      displayName: intl('Users.SSOConfig.OneLogin'),
      tid: 'onelogin',
    },
    adfs: {
      link: `${url}active directory single sign-on`,
      displayName: intl('Users.SSOConfig.ActiveDirectory'),
      tid: 'adfs',
    },
    azureAD: {
      link: `${url}azure single sign-on`,
      displayName: intl('Users.SSOConfig.AzureAD'),
      tid: 'azureAD',
    },
    okta: {
      link: `${url}okta single sign-on`,
      displayName: intl('Users.SSOConfig.Okta'),
      tid: 'okta',
    },
    ping: {
      link: `${url}ping identity single sign-on`,
      displayName: intl('Users.SSOConfig.PingIdentity'),
      tid: 'ping',
    },
  };
});

export const getAuthenticationSettingsPage = createSelector(
  isLDAPEnabled,
  getSettings,
  getHelpLinks,
  isSuperclusterMember,
  (ldapIsEnabled, settings, helpLinks, userIsSuperclusterMember) => ({
    ldapIsEnabled,
    settings,
    helpLinks,
    userIsSuperclusterMember,
  }),
);

export const isAuthenticationSettingsEnabled = createSelector(isUserOwner, userIsOwner => userIsOwner);
