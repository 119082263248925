/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {actionTypes} from 'redux-router5';
import {createSelector} from 'reselect';
import {generalUtils} from '@illumio-shared/utils/shared';
import {
  getUser,
  isSuperclusterMember,
  isUserAdmin,
  isUserOwner,
  isUserScoped,
  isUserWithReducedScope,
} from 'containers/User/UserState';

const defaultAppState = {
  loading: false,
  error: false,
};

export default {
  app(state = defaultAppState, action) {
    switch (action.type) {
      case 'LOADING_START':
        return {...state, loading: true};
      case 'LOADING_END':
        return {...state, loading: false};
      case 'ERROR':
        return {...state, error: action.error};
      default:
        return state;
    }
  },

  theme(state = 'lightning', action) {
    return action.type === 'SET_THEME' ? action.theme : state;
  },

  // Boolean if data is fetching
  transitionFetching(state = false, action) {
    switch (action.type) {
      case 'FETCHING_DATA_START':
        return true;
      case 'FETCHING_DATA_END':
        return false;
      default:
        return state;
    }
  },

  // Boolean if data is fetching until router finish state
  // getRoute returns transitionRoute when this is true
  transitionFetchingToEnd(state = false, action) {
    switch (action.type) {
      case 'FETCHING_DATA_START':
        return true;
      case actionTypes.CANCEL_TRANSITION:
      case actionTypes.TRANSITION_ERROR:
      case actionTypes.TRANSITION_SUCCESS:
        return false;
      default:
        return state;
    }
  },

  transitionFetchingRoutes(state = [], action) {
    switch (action.type) {
      case 'FETCHING_DATA_START':
        return action.routes;
      case 'FETCHING_DATA_END':
        return [];
      default:
        return state;
    }
  },

  system(state = {}, {type, data}) {
    switch (type) {
      case 'USER_LOGIN_SUCCESS':
        return _.pick(data, [
          'pce_cluster_type',
          'flow_analytics_enabled',
          'health_dashboard_enabled',
          'inactivity_expiration_minutes',
        ]);
      default:
        return state;
    }
  },

  product(state = {}, {type, data}) {
    switch (type) {
      case 'USER_LOGIN_SUCCESS':
        return {
          ...data.product_version,
          help_url: data.help_url,
          support_url: data.support_url,
          version_date: data.version_date,
          version_tag: data.version_tag,
          cs_integration_enabled: data.cs_integration_enabled,
          cs_workload_limit: data.cs_workload_limit,
        };
      default:
        return state;
    }
  },

  optionalFeatures(state = {}, {type, data}) {
    switch (type) {
      case 'OPTIONAL_FEATURES':
        return data.reduce((result, feature) => {
          result[feature.name] = feature;

          return result;
        }, {});
      default:
        return state;
    }
  },

  org(state = {}, {type, data}) {
    switch (type) {
      case 'GET_ORG_INSTANCE':
        return data;
      default:
        return state;
    }
  },

  orgSettings(state = {}, {type, data}) {
    switch (type) {
      case 'GET_ORG_SETTINGS':
        return data;
      default:
        return state;
    }
  },

  featureFlags(state = {}, action) {
    switch (action.type) {
      case 'GET_FEATURE_FLAGS':
        return action.data;
      default:
        return state;
    }
  },

  featureFlagDefaults(state = {}, action) {
    switch (action.type) {
      case 'GET_FEATURE_FLAG_DEFAULTS':
        return action.data;
      default:
        return state;
    }
  },

  clasMode(state = false, action) {
    switch (action.type) {
      case 'SET_CLAS_MODE':
        return action.data.clasMode;
      default:
        return state;
    }
  },
};

const getSystem = state => state.system;
export const getProduct = state => state.product;
export const getOrg = state => state.org;

export const getProductName = createSelector(getProduct, product => product.product_name);

export const reverseProviderConsumer = createSelector(getOrg, org => org.reverse_provider_consumer_column);

export const getFeatureFlags = state => state.featureFlags;
export const getFeatureFlagDefaults = state => state.featureFlagDefaults;

export const getFeatureFlagsEvaluator = createSelector(
  [getFeatureFlags, getFeatureFlagDefaults],
  (featureFlags, featureFlagDefaults) => {
    return flagToLookup => {
      if (_.has(featureFlags, flagToLookup) && !_.isNil(featureFlags[flagToLookup])) {
        return featureFlags[flagToLookup];
      }

      if (_.has(featureFlagDefaults, flagToLookup) && !_.isNil(featureFlagDefaults[flagToLookup])) {
        return featureFlagDefaults[flagToLookup];
      }

      return null;
    };
  },
);

export const getTheme = state => state.theme;

export const isSupercluster = createSelector(getSystem, system => Boolean(system.pce_cluster_type));

export const isHealthEnabled = createSelector(
  [getSystem, isUserScoped],
  (system, userIsScoped) => system.health_dashboard_enabled && !userIsScoped,
);

export const isOrgSaas = createSelector([getSystem], system => !system.health_dashboard_enabled);

export const getSessionExpirationMinutes = createSelector(
  getSystem,
  // Fallback to 20 minutes
  ({inactivity_expiration_minutes: minutes}) => (typeof minutes === 'number' && minutes) || 20,
);

export const getSupportUrl = createSelector(getProduct, product => product.support_url);

export const requireProvisionNote = createSelector(getOrg, org => org.require_sec_policy_commit_message);

export const getAppGroupLabelTypes = createSelector(getOrg, org => org.app_groups_default);

export const areVulnerabilitiesEnabled = state => state.optionalFeatures.vulnerability_analytics?.enabled ?? false;

export const isAsyncExplorerEnabled = state =>
  (state.optionalFeatures?.async_traffic_queries && state.optionalFeatures.async_traffic_queries?.enabled) ?? false;

export const isIlluminationApiEnabled = state => state.optionalFeatures.illumination?.enabled ?? false;

export const isNetworkEnforcementNodeEnabled = state =>
  state.optionalFeatures.network_enforcement_node?.enabled ?? false;

export const isLDAPEnabled = state =>
  !__MSP__ && (state.optionalFeatures.ldap_authentication_enabled?.enabled ?? false);

export const isCommonCriteriaEnabled = state => state.optionalFeatures.common_criteria_events_enabled?.enabled ?? false;

export const isEnhancedDataCollectionEnabled = state =>
  state.optionalFeatures.enhanced_data_collection?.enabled ?? false;

// Ransomware readiness dashboard
export const isRansomwareReadinessEnabled = state =>
  state.optionalFeatures.ransomware_readiness_dashboard?.enabled ?? false;

// Label edit warnings for enforced workloads
export const isLabelEditWarningForEnforcedWorkloadsEnabled = state =>
  state.optionalFeatures.labels_editing_warning_for_enforcement_mode?.enabled ?? false;

// Windows outbound process
export const isWindowsOutboundProcessEnforcementEnabled = state =>
  state.optionalFeatures.windows_outbound_process_enforcement?.enabled ?? false;

// Feature flag for displaying Reporting DB replication information.
export const isReportingEnabled = state => state.optionalFeatures.reporting?.enabled ?? false;

// For displaying core services settings menu item
export const isCoreServicesEnabled = state => state.optionalFeatures.core_services?.enabled ?? false;

// Security settings - IP Forwarding feature
export const isIPForwardingEnabled = state => state.optionalFeatures.ip_forwarding_firewall_setting?.enabled ?? false;

export const isIlluminationClassicEnabled = state => state.optionalFeatures.illumination_classic?.enabled ?? false;

// For collecting user analytics
export const isUIAnalyticsEnabled = state => !__DEV__ && (state.optionalFeatures.ui_analytics?.enabled ?? false);

export const isLabelBasedNetworkEnabled = state =>
  state.optionalFeatures?.label_based_network_detection?.enabled ?? false;

// For optional flag corporate_ips_groups to enable for multiple endpoints
export const isMultipleEndpointCorporateIPsEnabled = state =>
  state.optionalFeatures?.corporate_ips_groups?.enabled ?? false;

export const isRuleBasedLabelingEnabled = state => state.optionalFeatures?.rule_based_label_mapping?.enabled ?? false;

export const isLabelSettingsPageEnabled = createSelector(
  [isUserOwner, isUserAdmin, isSuperclusterMember],
  (userIsOwner, userIsAdmin, superclusterMember) =>
    __TARGET__ === 'core' && (userIsOwner || userIsAdmin) && !superclusterMember,
);

// More than 4 labels
// Move to the optionalFeatures API or turn to false before 22.3
export const isLabelTypeAdditionEnabled = () => true;

// API Key Settings
export const getOrgSettings = state => state.orgSettings;

export const getAdvancedRulesetDisplay = createSelector(
  getOrgSettings,
  org => !__ANTMAN__ && org.advanced_ruleset_display,
);

/**
 * Router selectors
 */
export const getRouter = state => state.router;

// Select previous route
// Note: router.previousRoute can be null. (e.g. page refresh)
export const getRoutePrevious = createSelector(getRouter, router => router.previousRoute);

export const getRoutePreviousName = createSelector(getRoutePrevious, previousRoute => previousRoute?.name);

export const getRoutePreviousParams = createSelector(getRoutePrevious, previousRoute => previousRoute?.params);

// Select current route. If a transition is happening, it will return a route before transition
export const getRouteCurrent = createSelector(getRouter, router => router.route);
export const getRouteCurrentName = state => getRouteCurrent(state).name;
export const getRouteCurrentParams = state => getRouteCurrent(state).params;

// Select route, if a transition is happening, it will return a future route we are transitioning to
export const getRoute = state => {
  const router = getRouter(state);
  const route = state.transitionFetchingToEnd ? router.transitionRoute : router.route;

  return route;
};
export const getRouteName = state => getRoute(state).name;
export const getRouteParams = state => getRoute(state).params;

export const getIsCSFrame = createSelector([getRouteCurrentParams], params => params.csframe === 'true');
export const getWillBeCSFrame = createSelector([getRouteParams], params => params.csframe === 'true');
export const getIsCSIpList = createSelector([getRouteParams], params => params.csiplist === 'true');

/** @type{(state: import('react-redux').DefaultRootState) => boolean} */

// flag for supporting K8s - see https://jira.illum.io/browse/EYE-90904
export const isKubernetesSupported = !__ANTMAN__ || (__ANTMAN__ && true);

// flag for UI Toggle - see https://jira.illum.io/browse/EYE-96054
export const isUIToggleEnabled = __ANTMAN__ && true;

export const isParameterizedBuild = createSelector([getProduct], product => product.parameterized === true);

export const getWorkloadMaximum = createSelector(
  [getProduct],
  // TODO: Need to check cs_integration_enabled first before trying to read cs_workload_limit per backend developer
  // This will probably change when we mix when cs_integration_enabled flag is irrelevant
  product => (product.cs_integration_enabled === true ? product.cs_workload_limit : Number.POSITIVE_INFINITY),
);

/**
 *  Version mismatch
 */
export const getVersion = createSelector(getProduct, product => product.version);

export const getParsedVersion = createSelector(
  getVersion,
  version => version.match(/^(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+).*/).groups,
);

export const getParsedVersionNumber = createSelector(getParsedVersion, version => _.mapValues(version, Number));

export const getPCEBuild = createSelector([getProduct], product => product.build);

export const getVersionMismatch = createSelector([getVersion, isParameterizedBuild], (pceVersion, parameterized) =>
  generalUtils.getVersionMismatch({pceVersion, parameterized}),
);

export const getUIVersion = createSelector([], () => generalUtils.getVersion(process.env.UI_VERSION));

export const getParsedUIVersionNumber = createSelector(getUIVersion, version => generalUtils.parseVersion(version));

export const getHelpUrl = () => 'https://docs.illumio.com/s/documentation-search#q=';

export const getDocsUrl = createSelector(getHelpUrl, domain => domain);

export const getKbDocsUrl = createSelector(getSupportUrl, url => `${url}/knowledge-base/articles`);

export const getAppState = state => ({
  routeName: getRouteName(state),
  previousRoute: getRoutePrevious(state),
  system: getSystem(state),
  userIsWithReducedScope: isUserWithReducedScope(state),
  uiAnalyticsIsEnabled: isUIAnalyticsEnabled(state),
  user: getUser(state),
  isCSFrame: getIsCSFrame(state),
  theme: getTheme(state),
  ruleBasedLabelingIsEnabled: isRuleBasedLabelingEnabled(state),
});

export const areStatePropsEqual = (next, prev) =>
  next.routeName.split('.')[1] === prev.routeName.split('.')[1] &&
  next.system.inactivity_expiration_minutes === prev.system.inactivity_expiration_minutes &&
  next.previousRoute === prev.previousRoute &&
  next.theme === prev.theme;

// Flag for supporting CLAS (Cluster Local Actor Store) - see https://jira.illum.io/browse/EYE-108677
export const isClasEnabled = state => state.clasMode;
